/*###########################################################################
// Image collage
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { debounce } from "@js/utilities/_throttleDebounce"

gsap.registerPlugin(ScrollTrigger)

const imageCollage = {
  config: {
    imageCollage: '.imageCollage'
  },

  elements: {},

  init() {
    const self = this

    self.elements.imageCollage = document.querySelectorAll(self.config.imageCollage)

    if (self.elements.imageCollage !== null) {
      for (let i = 0; i < self.elements.imageCollage.length; i++) {
        const imageCollage = self.elements.imageCollage[i]

        self.handleImageCollage(imageCollage)
      }
    }
  },

  handleImageCollage(imageCollage) {
    const self = this

    const imageCollageImages = imageCollage.querySelectorAll('.imageCollage div[data-image]')
    const imageCollageText = imageCollage.querySelector('.imageCollage__text')

    // Initial sets for images and text
    //
    for (let i = 0; i < imageCollageImages.length; i++) {
      const imageCollageImage = imageCollageImages[i]

      const initialX = window.innerWidth / 2 - imageCollageImage.clientWidth / 2
      const initialY = window.innerHeight / 2 - imageCollageImage.clientHeight / 2

      gsap.set(imageCollageImage, { x: initialX, y: initialY, autoAlpha: 0 });
    }
    gsap.set(imageCollageText, { y: window.innerHeight / 10, autoAlpha: 0 });

    // Enter animations
    //
    let tl = gsap.timeline({
      paused: true
    });

    for (let i = 0; i < imageCollageImages.length; i++) {
      const imageCollageImage = imageCollageImages[i]

      let x = 0
      let y = 0

      if (i === 0) {
        x = window.innerWidth < 768 ? window.innerWidth / 24 : window.innerWidth / 10
        y = window.innerWidth < 768 ? window.innerHeight / 6 : window.innerHeight / 8
      } else if (i === 1) {
        x = window.innerWidth < 768 ? window.innerWidth / 4 : window.innerWidth / 1.6
        y = window.innerWidth < 768 ? window.innerHeight / 4 : window.innerHeight / 12
      } else if (i === 2) {
        x = window.innerWidth < 768 ? window.innerWidth / 1.5 : window.innerWidth / 1.2
        y = window.innerWidth < 768 ? window.innerHeight / 10 : window.innerHeight / 8
      } else if (i === 3) {
        x = window.innerWidth < 768 ? window.innerWidth / 12 : window.innerWidth / 10
        y = window.innerWidth < 768 ? window.innerHeight / 2 : window.innerHeight / 1.5
      } else if (i === 4) {
        // Hidden md up
        //
        x = window.innerWidth / 6
        y = window.innerHeight / 1.8
      } else if (i === 5) {
        x = window.innerWidth < 768 ? window.innerWidth / 1.75 : window.innerWidth / 1.3
        y = window.innerWidth < 768 ? window.innerHeight / 2.25 : window.innerHeight / 1.7
      }

      tl.to(imageCollageImage, {
        duration: 1.5 + (i/20),
        x: x,
        y: y,
        autoAlpha: 1,
        ease: "power2.out"
      }, "-=1.5")

      if (i === imageCollageImages.length-1) {
        tl.to(imageCollageText, {
          duration: 1.5,
          y: 0,
          autoAlpha: 1,
          ease: "power2.out",
        }, "-=1.5")
      }
    }

    const enter = (scrollTriggerInstance) => {
      scrollTriggerInstance.kill(false, true)
    }

    ScrollTrigger.create({
      trigger: imageCollage,
      pin: false,
      start: "top +=" + window.innerHeight/2 + " middle",
      markers: false,
      animation: tl,
      // onEnter: enter,
      // onLeave: self => self.kill(false, true)
    })
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    imageCollage.init()
  }, 500)
})
