/*###########################################################################
// Slider testimonials
//#########################################################################*/

import Swiper from "swiper"
import { debounce } from "@js/utilities/_throttleDebounce"

const sliderTestimonials = {
  config: {
    sliderTestimonials: '.sliderTestimonials'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderTestimonials = document.querySelectorAll(self.config.sliderTestimonials)

    if (self.elements.sliderTestimonials !== null) {
      for (let i = 0; i < self.elements.sliderTestimonials.length; i++) {
        const sliderTestimonials = self.elements.sliderTestimonials[i]

        self.handleSliderTestimonials(sliderTestimonials)
      }
    }
  },

  initSlider(slider) {
    const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')

    const swiper = new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: false,
      autoHeight: true,
      breakpoints: {
        // when window width is >= 1024px
        1024: {
          slidesPerView: 2,
          spaceBetween: 32,
          autoHeight: false
        },
        1280: {
          slidesPerView: 2,
          spaceBetween: 40,
          autoHeight: false
        }
      }
    })

    if (prevBtn !== null && nextBtn !== null && swiper && !swiper.destroyed) {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      swiper.on('slideChange', function() {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }

        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }
      })

      swiper.on('fromEdge', function() {
        prevBtn.classList.remove('is-disabled')
        nextBtn.classList.remove('is-disabled')
      })

      swiper.on('reachBeginning', function () {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }
      })

      swiper.on('reachEnd', function () {
        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }
      })

      prevBtn.addEventListener('click', function (e) {
        swiper.slidePrev(500)
      })

      nextBtn.addEventListener('click', function (e) {
        swiper.slideNext(500)
      })

      // Workaround for weird bug of miscalculations
      //
      swiper.update()
    }

    return swiper
  },

  handleSliderTestimonials(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth
    
    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        swiper.destroy()

        setTimeout(function() {
          swiper = self.initSlider(slider)
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('load', function () {
  sliderTestimonials.init()
})
