/*###########################################################################
// Products entry
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const productsEntry = {
  config: {
    productsEntry: '.productsEntry'
  },

  elements: {},

  init() {
    const self = this

    self.elements.productsEntryEls = document.querySelectorAll(self.config.productsEntry)

    if (self.elements.productsEntryEls !== null) {
      if (window.innerWidth >= 1024) {
        for (let i = 0; i < self.elements.productsEntryEls.length; i++) {
          const productsEntryEl = self.elements.productsEntryEls[i]

          self.handleProductsEntry(productsEntryEl)
        }
      }
    }
  },

  handleProductsEntry(productsEntryEl) {
    const self = this

    const swiperControls = productsEntryEl.querySelector('.slider__controls')
    const swiperSlides = productsEntryEl.querySelectorAll('.swiper-slide')
    const textContent = productsEntryEl.querySelector('.productsEntry__content')

    for (let i = 0; i < swiperSlides.length; i++) {
      const swiperSlide = swiperSlides[i]

      gsap.set(swiperSlide, { x: (window.innerWidth - (i * 400)) });
      gsap.to(swiperSlide, {
        x: "0px",
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: productsEntryEl,
          start: "top +=" + (window.innerHeight / 2) + " middle",
          onLeave: self => self.kill(false, true)
        }
      })
    }

    if (textContent !== null) {
      gsap.set(textContent, { opacity: 0 });
      gsap.to(textContent, {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: productsEntryEl,
          start: "top +=" + (window.innerHeight / 2) + " middle",
          onLeave: self => self.kill(false, true)
        }
      })
    }

    if (swiperControls !== null) {
      gsap.set(swiperControls, { opacity: 0 });
      gsap.to(swiperControls, {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: productsEntryEl,
          start: "top +=" + (window.innerHeight / 2) + " middle",
          onLeave: self => self.kill(false, true)
        }
      })
    }
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    productsEntry.init()
  }, 250)
})
