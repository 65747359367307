/*###########################################################################
// Approach
//#########################################################################*/

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import lottie from 'lottie-web'
import { inViewport } from '../utilities/_inViewport'
import { throttle } from '../utilities/_throttleDebounce'

const approach = {
  config: {
    approach: '.approach',
    approachPanel: '.approach__panel',
    approachPanelAnimation: '.approach__panel__animation'
  },

  elements: {
    approach: null
  },

  init() {
    const self = this

    self.elements.approach = document.querySelector(self.config.approach)

    if (self.elements.approach !== null && window.innerWidth >= 1024) {
      self.elements.approachPanels = Array.from(self.elements.approach.querySelectorAll(self.config.approachPanel))

      self.handleApproach()
      self.handleLotties()
    } else if (self.elements.approach !== null) {
      self.elements.approachPanels = Array.from(self.elements.approach.querySelectorAll(self.config.approachPanel))

      self.handleLotties()
    }
  },

  handleApproach() {
    const self = this

    let animation = gsap.timeline({
      paused: true
    })

    self.elements.approachPanels.forEach((panel, index) => {
      const isLast = index === self.elements.approachPanels.length - 1

      animation.to(panel, {
        duration: 1,
        delay: 0.2,
        opacity: isLast ? 1 : 0,
        scrollTrigger: {
          trigger: panel,
          start: 'top top',
          scrub: 0.5,
          toggleActions: "play pause resume reverse",
        }
      }, '+=1')
    })
  },

  handleLotties() {
    const self = this

    self.elements.approachPanels.forEach((panel, index) => {
      const animationEl = panel.querySelector(self.config.approachPanelAnimation)
      const lottiePath = panel.getAttribute('data-lottie')

      // Load lottie
      let isPlaying = false
      const lottieInstance = lottie.loadAnimation({
        container: animationEl,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: lottiePath,
        rendererSettings: {
          // Log events for debugging
          onLoaded: () => console.log('Animation loaded'),
          onError: e => console.error('Error loading animation:', e),
        }
      })

      window.addEventListener('scroll', throttle(function() {
        if (isPlaying === false && inViewport(animationEl, window.innerHeight / 4)) {
          lottieInstance.play()
          isPlaying = true
        }
      }, 500))
    })
  }
}

window.addEventListener('load', function () {
  approach.init()
})
