const videoBlock = {
  config: {
    videoBlock: '.videoBlock'
  },

  elements: {},

  init() {
    const self = this

    self.elements.videoBlocks = document.querySelectorAll(self.config.videoBlock)

    if (self.elements.videoBlocks !== null) {
      for (let i = 0; i < self.elements.videoBlocks.length; i++) {
        const videoBlock = self.elements.videoBlocks[i]

        self.handleVideoBlock(videoBlock)
      }
    }
  },

  handleVideoBlock(videoBlock) {
    const self = this

    const overlay = videoBlock.querySelector('.videoBlock__overlay')
    const iframe = videoBlock.querySelector('iframe')

    overlay.addEventListener('click', function() {
      overlay.classList.add('opacity-0')
      iframe.src = iframe.getAttribute('data-src')
      
      // Reload video once visible to force autoplay
      //
      setTimeout(function() {
        iframe.src = iframe.getAttribute('src')
      }, 600)

      setTimeout(function() {
        overlay.classList.add('hidden')
      }, 600)
    })
  }
}

window.addEventListener('load', function () {
  videoBlock.init()
})