/*###########################################################################
// Gallery entry
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const galleryEntry = {
  config: {
    galleryEntry: '.galleryEntry'
  },

  elements: {},

  init() {
    const self = this

    self.elements.galleryEntryEls = document.querySelectorAll(self.config.galleryEntry)

    if (self.elements.galleryEntryEls !== null) {
      if (window.innerWidth >= 1024) {
        for (let i = 0; i < self.elements.galleryEntryEls.length; i++) {
          const galleryEntryEl = self.elements.galleryEntryEls[i]

          self.handleGalleryEntry(galleryEntryEl)
        }
      }
    }
  },

  handleGalleryEntry(galleryEntryEl) {
    const self = this

    const swiperControls = galleryEntryEl.querySelector('.slider__controls')
    const swiperSlides = galleryEntryEl.querySelectorAll('.swiper-slide')

    for (let i = 0; i < swiperSlides.length; i++) {
      const swiperSlide = swiperSlides[i]

      gsap.set(swiperSlide, { x: (window.innerWidth - (i * 400)) });
      gsap.to(swiperSlide, {
        x: "0px",
        duration: 2,
        ease: "power4.out",
        scrollTrigger: {
          trigger: galleryEntryEl,
          start: "top +=" + (window.innerHeight / 2) + " middle",
          onLeave: self => self.kill(false, true)
        }
      })
    }

    gsap.set(swiperControls, { opacity: 0 });
    gsap.to(swiperControls, {
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: galleryEntryEl,
        start: "top +=" + (window.innerHeight / 2) + " middle",
        onLeave: self => self.kill(false, true)
      }
    })
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    galleryEntry.init()
  }, 250)
})
