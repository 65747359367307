/*###########################################################################
// Slider tabs
//#########################################################################*/

import Swiper from 'swiper'

const sliderTabs = {
  config: {
    sliderTabs: '.sliderTabs'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderTabs = document.querySelectorAll(self.config.sliderTabs)

    if (self.elements.sliderTabs !== null) {
      for (let i = 0; i < self.elements.sliderTabs.length; i++) {
        const sliderTabs = self.elements.sliderTabs[i]

        self.handleSliderTabs(sliderTabs)
      }
    }
  },

  handleSliderTabs(slider) {
    const self = this

    const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')

    const swiper = new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 32,
      loop: false,
      autoHeight: true,
    })

    if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled')
    }

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled')
      nextBtn.classList.remove('is-disabled')
    })

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }
    })

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev()
    })

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext()
    })
  }
}

window.addEventListener('load', function () {
  sliderTabs.init()
})
