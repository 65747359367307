/*###########################################################################
// Entry filter
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const entryFilter = {
  config: {
    entryFilter: '.entry-filter'
  },

  elements: {
    entryFilter: null,
  },

  init() {
    const self = this

    self.elements.entryFilter = document.querySelector(self.config.entryFilter)

    if (self.elements.entryFilter !== null) {
      if (typeof htmx !== 'undefined') {
        // Add loading state
        //
        htmx.on('htmx:beforeRequest', function (event) {
          document.querySelector('#entry-container').classList.add('is-loading')
        })

        // After swap re-add event listeners for the above and remove loading state
        //
        htmx.on('htmx:afterSwap', function (event) {
          document.querySelector('#entry-container').classList.remove('is-loading')

          // Scroll to entries
          self.scrollToContainer()
        })
      }
    }
  },

  scrollToContainer() {
    const offsetAmount = document.querySelector('.header').clientHeight + 40
    scrollTo(document.querySelector('#entry-container'), -offsetAmount)
  }

}

window.addEventListener('load', function () {
  entryFilter.init()
})