import GLightbox from 'glightbox'

const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
        <div class="gloader visible"></div>
        <div class="goverlay"></div>
        <div class="gcontainer">
        <div id="glightbox-slider" class="gslider"></div>
        <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">
          <div class="iconCta iconCta--red iconCta--small-lgDown">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 14.7" width="19.5" height="14.7">
              <path d="M19.2 6.7 12.8.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4.7 4.7H1c-.6 0-1 .4-1 1s.4 1 1 1h15.1L11.4 13c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0L19.2 8c.4-.3.4-1 0-1.3z" fill="#ffffff"/>
            </svg>
          </div>
        </button>
        <button class="gprev gbtn" tabindex="1" aria-label="Previous">
          <div class="iconCta iconCta--red iconCta--small-lgDown">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 14.7" width="19.5" height="14.7">
              <path d="m.3 8.1 6.4 6.4c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L3.4 8.4h15.1c.6 0 1-.4 1-1s-.4-1-1-1H3.4l4.7-4.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L.3 6.7c-.4.3-.4 1 0 1.4z" fill="#ffffff"/>
            </svg>
          </div>
        </button>
        <button class="gclose gbtn" tabindex="2" aria-label="Close">
          <div class="iconCta iconCta--red iconCta--small-lgDown">
            <svg viewBox="0 0 13 14" width="13" height="14" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.371 1.129a1 1 0 0 0-1.414 0L6.5 5.586 2.043 1.129A1 1 0 1 0 .629 2.543L5.086 7 .629 11.457a1 1 0 1 0 1.414 1.414L6.5 8.414l4.457 4.457a1 1 0 0 0 1.414-1.414L7.914 7l4.457-4.457a1 1 0 0 0 0-1.414Z" fill="#ffffff"/>
            </svg>
          </div>
        </button>
    </div>
    </div>`;

window.addEventListener('load', function() {
    const lightbox = GLightbox({
        lightboxHTML: customLightboxHTML,
        touchNavigation: true,
        loop: true
    })

    if (typeof htmx !== 'undefined') {
        // After swap re-add event listeners for the above and remove loading state
        //
        htmx.on('htmx:afterSwap', function (event) {
            const lightbox = GLightbox({
								lightboxHTML: customLightboxHTML,
                touchNavigation: true,
                loop: true
            })
        })
    }
})
