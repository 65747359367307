/*###########################################################################
// Slider logos
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';

const sliderLogos = {
  config: {
    sliderLogos: '.sliderLogos'
  },

  elements: {
    sliderLogos: null
  },

  init() {
    const self = this

    self.elements.sliderLogos = document.querySelectorAll(self.config.sliderLogos)

    if (self.elements.sliderLogos !== null) {
      for (let i = 0; i < self.elements.sliderLogos.length; i++) {
        const slider = self.elements.sliderLogos[i]

        self.handleSliderLogos(slider)
      }
    }
  },

  handleSliderLogos(slider) {
    const self = this

    const sliderInstance = new Swiper(slider, {
      modules: [Autoplay],
      spaceBetween: 10,
      centeredSlides: true,
      speed: 5000,
      autoplay: {
        delay: 1
      },
      loop: true,
      slidesPerView: 4.25,
      allowTouchMove: false,
      disableOnInteraction: false,
      breakpoints: {
        // when window width is >= 320px
        375: {
          slidesPerView: 4.5,
          spaceBetween: 16
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 4.75,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 5.25,
          spaceBetween: 24
        },
        768: {
          slidesPerView: 7.5,
          spaceBetween: 32
        },
        1024: {
          slidesPerView: 8.25,
          spaceBetween: 32
        },
        1280: {
          slidesPerView: 9.5,
          spaceBetween: 40
        },
        1440: {
          slidesPerView: 10,
          spaceBetween: 40
        },
        1600: {
          slidesPerView: 11,
          spaceBetween: 40
        }
      }
    })
  }
}

window.addEventListener('load', function () {
  sliderLogos.init()
})
