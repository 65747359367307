/*###########################################################################
// Slider events
//#########################################################################*/

import Swiper from "swiper"
import { Autoplay, EffectCreative, EffectFade } from 'swiper/modules'
import { throttle, debounce } from "@js/utilities/_throttleDebounce"

import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
gsap.registerPlugin(TextPlugin)

const sliderEvents = {
  config: {
    sliderEvents: '.sliderEvents'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderEvents = document.querySelectorAll(self.config.sliderEvents)

    if (self.elements.sliderEvents !== null) {
      for (let i = 0; i < self.elements.sliderEvents.length; i++) {
        const sliderEvents = self.elements.sliderEvents[i]

        self.handleSliderEvents(sliderEvents)
      }
    }
  },

  initSlider(slider) {
    const self = this

    const section = slider.closest('.sliderEvents__section')

    const paginationSpans = section.querySelectorAll('.sliderEvents__pagination span')
    const prevBtn = section.querySelector('.slider__control--prev')
    const nextBtn = section.querySelector('.slider__control--next')
    const sliderText = section.querySelector('.sliderEvents__text')
    const sliderCounter = section.querySelector('.sliderEvents__counter')

    const swiper = new Swiper(slider, {
      modules: [EffectCreative, Autoplay],
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: false,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      autoplay: {
        delay: 5000,
      },
      loop: false,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: true
    })

    const swiperText = new Swiper(sliderText, {
      modules: [EffectFade],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      speed: 800,
      allowTouchMove: false,
    })

    setTimeout(function() {
      // Handle pagination clicks
      //
      for (let i = 0; i < paginationSpans.length; i++) {
        const paginationSpan = paginationSpans[i]
        self.handlePaginationSpan(paginationSpan, swiper, swiperText, section)
      }
    }, 300)

    if (swiper && !swiper.destroyed) {
			swiper.on('slideChange', throttle(function() {
				for (let i = 0; i < paginationSpans.length; i++) {
					const paginationSpan = paginationSpans[i]
					paginationSpan.classList.remove('is-current')
				}
				paginationSpans[swiper.realIndex].classList.add('is-current')

				// Update slider text
				swiperText.slideTo((swiper.realIndex), 500)

        // Update counter
        gsap.to(sliderCounter, {
          duration: 0.25,
          text: {
            value: "0" + (swiper.realIndex + 1),
            newClass: "animating-in",
            oldClass: "animating-out"
          },
          ease: "none",
        });
      }, 250))



      // Workaround for weird bug of miscalculations
      //
      swiper.update()
    }

    return swiper
  },

  handlePaginationSpan(paginationSpan, swiper, swiperText, section) {
    const self = this

		const paginationSpans = section.querySelectorAll('.sliderEvents__pagination span')
		const sliderCounter = section.querySelector('.sliderEvents__counter')

    if (window.innerWidth >= 1024) {
      paginationSpan.addEventListener('click', function() {
        swiper.slideTo((parseInt(paginationSpan.getAttribute('data-slide'))), 500)
				swiperText.slideTo((parseInt(paginationSpan.getAttribute('data-slide'))), 500)

				for (let i = 0; i < paginationSpans.length; i++) {
					const paginationSpan = paginationSpans[i]
					paginationSpan.classList.remove('is-current')
				}
				paginationSpan.classList.add('is-current')

				// Update counter
        gsap.to(sliderCounter, {
          duration: 0.25,
          text: {
            value: "0" + ((parseInt(paginationSpan.getAttribute('data-slide'))) + 1),
            newClass: "animating-in",
            oldClass: "animating-out"
          },
          ease: "none",
        });
      })
    }
  },

  handleSliderEvents(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        setTimeout(function() {
          swiper.update()
          swiper.updateProgress()
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('load', function () {
  sliderEvents.init()
})
