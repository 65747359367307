/*###########################################################################
// Gradients
//#########################################################################*/

import lottie from 'lottie-web'

// Load lottie
lottie.loadAnimation({
  container: document.querySelector('.main-gradient'),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '/dist/lottie/main-gradient-dark.json',
  rendererSettings: {
    // Log events for debugging
    onLoaded: () => console.log('Animation loaded'),
    onError: e => console.error('Error loading animation:', e),
  }
}).setSpeed(0.2);

// Load lottie
lottie.loadAnimation({
  container: document.querySelector('.footer-gradient'),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '/dist/lottie/footer-gradient.json',
  rendererSettings: {
    // Log events for debugging
    onLoaded: () => console.log('Animation loaded'),
    onError: e => console.error('Error loading animation:', e),
  }
}).setSpeed(0.2);

window.addEventListener('load', function() {
	const gradients = document.querySelectorAll('.gradient')

	for (let i = 0; i < gradients.length; i++) {
		const gradient = gradients[i]

		// Load lottie
		lottie.loadAnimation({
			container: gradient,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: '/dist/lottie/gradient.json',
			rendererSettings: {
				// Log events for debugging
				onLoaded: () => console.log('Animation loaded'),
				onError: e => console.error('Error loading animation:', e),
			}
		}).setSpeed(0.1);
	}
})
