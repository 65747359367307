const forms = {
    config: {
        textField: '.text-field'
    }, 

    elements: {},

    init() {
        const self = this

        self.elements.textFields = document.querySelectorAll(self.config.textField)

        if (self.elements.textFields !== null) {
            for (let i = 0; i < self.elements.textFields.length; i++) {
                const textField = self.elements.textFields[i]
                
                if (textField.getAttribute('data-field-type') == 'date-time') {
                    self.handleDateInput(textField)
                } else {
                    self.handleInput(textField)
                }
            }
        }
    },

    handleDateInput(textField) {
        const inputHidden = textField.querySelector("input")
        const input = textField.querySelector(".input")

        inputHidden.addEventListener('change', function() {
            if (inputHidden.value !== '') {
                textField.classList.add('has--content')
            } else {
                textField.classList.remove('has--content')
            }
        })

        input.addEventListener('change', function() {
            if (inputHidden.value !== '') {
                textField.classList.add('has--content')
            } else {
                textField.classList.remove('has--content')
            }
        })

        input.addEventListener('focus', function() {
            textField.classList.add('has--focus')
        })

        input.addEventListener('blur', function() {
            textField.classList.remove('has--focus')
        })
    },

    handleInput(textField) {
        const input = textField.querySelector(".input")

        input.addEventListener('change', function() {
            if (input.value !== '') {
                textField.classList.add('has--content')
            } else {
                textField.classList.remove('has--content')
            }
        })

        input.addEventListener('focus', function() {
            textField.classList.add('has--focus')
        })

        input.addEventListener('blur', function() {
            textField.classList.remove('has--focus')
        })
    }
}

window.addEventListener('load', function() {
    forms.init()
})