/*###########################################################################
// Slider content
//#########################################################################*/

import Swiper from "swiper"
import { Autoplay, EffectFade } from 'swiper/modules'
import { debounce } from "@js/utilities/_throttleDebounce"

const sliderContent = {
  config: {
    sliderContent: '.sliderContent'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderContent = document.querySelectorAll(self.config.sliderContent)

    if (self.elements.sliderContent !== null) {
      for (let i = 0; i < self.elements.sliderContent.length; i++) {
        const sliderContent = self.elements.sliderContent[i]

        self.handleSliderContent(sliderContent)
      }
    }
  },

  initSlider(slider) {
    const self = this

    const section = slider.closest('.sliderContent__section')

    const paginationSpans = section.querySelectorAll('.sliderContent__pagination span')
    const prevBtn = section.querySelector('.slider__control--prev')
    const nextBtn = section.querySelector('.slider__control--next')
    const sliderText = section.querySelector('.sliderContent__text')

    const swiper = new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      autoHeight: true
    })

    const swiperText = new Swiper(sliderText, {
      modules: [EffectFade],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
    })

    setTimeout(function() {
      // Handle pagination clicks
      //
      for (let i = 0; i < paginationSpans.length; i++) {
        const paginationSpan = paginationSpans[i]
        self.handlePaginationSpan(paginationSpan, swiper)
      }
    }, 300)

    if (prevBtn !== null && nextBtn !== null && swiper && !swiper.destroyed) {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      swiper.on('slideChange', function() {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }

        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }

        // Reset pagination classes
        //
        for (let i = 0; i < paginationSpans.length; i++) {
          const paginationSpan = paginationSpans[i]
          paginationSpan.classList.remove('is-current')
        }
        paginationSpans[swiper.realIndex].classList.add('is-current')

        // Sync other slider
        //
        swiperText.slideTo(swiper.realIndex)
      })

      swiper.on('fromEdge', function() {
        prevBtn.classList.remove('is-disabled')
        nextBtn.classList.remove('is-disabled')
      })

      swiper.on('reachBeginning', function () {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }
      })

      swiper.on('reachEnd', function () {
        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }
      })

      prevBtn.addEventListener('click', function (e) {
        swiper.slidePrev(500)
        swiperText.slideTo(swiper.realIndex)
      })

      nextBtn.addEventListener('click', function (e) {
        swiper.slideNext(500)
        swiperText.slideTo(swiper.realIndex)
      })
      
      // Workaround for weird bug of miscalculations
      //
      swiper.update()
    }

    return swiper
  },

  handlePaginationSpan(paginationSpan, swiper) {
    const self = this

    if (window.innerWidth >= 1024) {
      paginationSpan.addEventListener('click', function() {
        swiper.slideTo((parseInt(paginationSpan.getAttribute('data-slide')) - 1))
      })
    }
  },

  handleSliderContent(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        setTimeout(function() {
          swiper.update()
          swiper.updateProgress()
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('load', function () {
  sliderContent.init()
})
