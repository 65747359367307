/*###########################################################################
// Content reveal
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Swiper from "swiper"
import { debounce } from "@js/utilities/_throttleDebounce"

gsap.registerPlugin(ScrollTrigger)

const contentReveals = {
  config: {
    contentReveal: '.contentReveal'
  },

  elements: {},

  init() {
    const self = this

    self.elements.contentReveals = document.querySelectorAll(self.config.contentReveal)

    if (self.elements.contentReveals !== null) {
      if (window.innerWidth >= 1024) {
        for (let i = 0; i < self.elements.contentReveals.length; i++) {
          const contentReveal = self.elements.contentReveals[i]

          self.handleContentReveal(contentReveal)
        }
      } else {
        for (let i = 0; i < self.elements.contentReveals.length; i++) {
          const contentReveal = self.elements.contentReveals[i]

          self.handleContentRevealSlider(contentReveal)
        }
      }
    }
  },

  handleContentReveal(contentReveal) {
    const self = this

    const contentRevealSection = contentReveal.closest('.contentReveal__section')
    const contentRevealItems = contentReveal.querySelectorAll('.contentReveal__item')
    const contentRevealText = contentRevealSection.querySelector('.contentReveal__text')

    let tl = gsap.timeline({
      paused: true,
      scrollTrigger:{
        trigger: contentRevealSection,
        ease: "power2.out",
        pin: true,
        scrub: 1,
        toggleActions:"restart none none reset",
        onEnter: function() {
          document.querySelector('.header').classList.add('header--hidden')
        },
        onEnterBack: function() {
          document.querySelector('.header').classList.add('header--hidden')
        },
        onLeave: function() {
          document.querySelector('.header').classList.remove('header--hidden')
        },
        onLeaveBack: function() {
          document.querySelector('.header').classList.remove('header--hidden')
        }
      }
    });

    gsap.set(contentRevealText, { x: (window.innerWidth / 4), autoAlpha: 0 });

    for (let i = 0; i < contentRevealItems.length; i++) {
      const contentRevealItem = contentRevealItems[i]

      if (i !== 0) {
        gsap.set(contentRevealItem, { x: (contentRevealItem.clientWidth * i) + (30 * i), zIndex: 10 - (i+1) });

        tl.to(contentRevealItem, {
          duration: 1.5 + (i/20),
          x: (30 * i),
          webkitFilter: "brightness(" + (1 - (i/8)) + ")",
          filter: "brightness(" + (1 - (i/10)) + ")",
          ease: "power2.out",
        }, "-=1.5")
      }

      if (i === contentRevealItems.length-1) {
        tl.to(contentRevealText, {
          duration: 1.5,
          x: 0,
          autoAlpha: 1,
          ease: "power2.out",
        }, "-=1.5")
      }
    }
  },

  handleContentRevealSlider(contentReveal) {
    const self = this

    const contentRevealWrapper = contentReveal.querySelector('.contentReveal__wrapper')
    const contentRevealItems = contentReveal.querySelectorAll('.contentReveal__item')

    // Add Swiper classes before init 
    // 
    contentReveal.classList.add('sliderContentReveal')
    contentRevealWrapper.classList.add('swiper-wrapper')
    for (let i = 0; i < contentRevealItems.length; i++) {
      const contentRevealItem = contentRevealItems[i]
      contentRevealItem.classList.add('swiper-slide')
    }

    // Handle slider
    //
    let swiper = self.initSlider(contentReveal)    
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        if (window.innerWidth < 1024) {
          swiper.destroy()
        }
        
        setTimeout(function() {
          if (window.innerWidth < 1024) {
            swiper = self.initSlider(contentReveal)
          }
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  },

  initSlider(slider) {
    const self = this

    const swiper = new Swiper(slider, {
      slidesPerView: 1.25,
      spaceBetween: 16,
      loop: false,
      autoHeight: true,
      breakpoints: {
        360: {
          slidesPerView: 1.35,
          spaceBetween: 16,
        },
        414: {
          slidesPerView: 2.25,
          spaceBetween: 16,
        },
        520: {
          slidesPerView: 2.75,
          spaceBetween: 32,
        },
        700: {
          slidesPerView: 3.25,
          spaceBetween: 32,
        },
      }
    })

    return swiper
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    contentReveals.init()
  }, 250)
})
