/*###########################################################################
// Hero home
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../vendor/_SplitText"
import { throttle } from "../utilities/_throttleDebounce"
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

const heroHome = {
  config: {
    heroHome: '.heroHome',
    heroHomeHeading: '.heroHome__heading'
  }, 

  elements: {},

  init() {
    const self = this

    self.elements.heroHome = document.querySelector(self.config.heroHome)

    if (self.elements.heroHome !== null) {
      self.elements.heroHomeHeading = self.elements.heroHome.querySelector(self.config.heroHomeHeading)

      // Set loaded class
      //
      self.elements.heroHome.classList.add('has-loaded')

      // Handle 100vh height
      //
      self.elements.heroHome.style.height = window.innerHeight + 'px'

      window.addEventListener('resize', throttle(function() {
        self.elements.heroHome.style.height = ''
        
        setTimeout(function() {
          self.elements.heroHome.style.height = window.innerHeight + 'px'
        }, 100)
      }, 250))
      
      // Handle hero heading
      //
      self.handleHeroHeading()

      // Handle section below offset
      //
      self.handleSectionBelowOffset()
    }
  },

  handleHeroHeading() {
    const self = this

    let tl = gsap.timeline()

    let split = new SplitText(self.elements.heroHomeHeading, {
      type: "chars",
    })

    tl.from(split.chars, {
      delay: 0.5,
      duration: 0.6,
      opacity: 0,
      scale: 0.9,
      y: window.innerHeight / 18,
      transformOrigin: "0% 50% -50",
      ease: "power1.out",
      stagger: 0.03
    })
  },

  handleSectionBelowOffset() {
    const self = this

    const getSizeOffsetAccountValue = () => {
      if (window.innerWidth < 1280) {
        return window.innerWidth / 55
      } else if (window.innerWidth >= 1280 && window.innerWidth < 1440) {
        return window.innerWidth / 65
      } else if (window.innerWidth >= 1440 && window.innerWidth < 1600) {
        return window.innerWidth / 75
      } else {
        return window.innerWidth / 125
      }
    }

    const sectionBelow = self.elements.heroHome.nextElementSibling.firstElementChild
    let sizeOffsetAccountValue = getSizeOffsetAccountValue() 
    let offsetAmount = self.elements.heroHomeHeading.parentNode.clientHeight / 2 + sizeOffsetAccountValue

    sectionBelow.style.marginTop = -offsetAmount + 'px'

    window.addEventListener('resize', throttle(function() {
      sizeOffsetAccountValue = getSizeOffsetAccountValue() 
      offsetAmount = self.elements.heroHomeHeading.parentNode.clientHeight / 2 + sizeOffsetAccountValue

      sectionBelow.style.marginTop = -offsetAmount + 'px'
    }, 250))
  }
}

window.addEventListener('load', function() {
  heroHome.init()
})